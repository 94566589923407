import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import SettingsIcon from '@mui/icons-material/Settings';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControlLabel,
  IconButton,
  Link,
  styled,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import useProviderPermissionState from 'hooks/authorization/useProviderPermissionState';
import BotLanguageSelect from 'pages/Calendar/components/BotLanguageSelect';
import BotSummaryTemplateSelect from 'pages/Calendar/components/BotSummaryTemplateSelect';
import SynchronizingContainer from 'pages/Calendar/components/SynchronizingContainer';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import appSelectors from 'redux/App/app.selectors';
import appActions from 'redux/App/app.actions';
import calendarActions from 'redux/Calendar/calendar.actions';
import calendarSelectors from 'redux/Calendar/calendar.selectors';
import api from 'redux/services/api';
import { CalendarEvent } from 'redux/services/api.types';
import { useBotMutation, useGetEventsQuery } from 'redux/services/calendar';
import { Video } from 'redux/services/video.types';
import Header from 'ui-kit/header/Header';
import ContainerFluid from 'ui-kit/layout/ContainerFluid';
import ContainerNarrow from 'ui-kit/layout/ContainerNarrow';
import AuthorizeCalendarPermissionCard from './components/AuthorizeCalendarPermissionCard';
import RecordingSettingsPanel from './components/RecordingSettingsPanel';
import { useGetOrganizationQuery } from '../../redux/services/organization';

dayjs.extend(weekOfYear);

const CalendarStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;

  .MuiFormControlLabel-label {
    font-size: 14px;
  }

  .calendar-navigation {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .calendar-navigation-info {
      align-self: center;
    }
  }

  .day {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .date {
      .date-container {
        width: 50px;
        height: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        .date-day {
          font-size: 11px;
          line-height: 11px;
        }

        .date-number {
          font-size: 18px;
          line-height: 18px;
        }

        .date-month {
          font-size: 11px;
          line-height: 11px;
        }
      }
    }

    .date-today {
      color: ${({ theme }) => theme.palette.primary.main};
      border: 1px solid ${({ theme }) => theme.palette.primary.main};
      border-radius: 4px;
    }

    .events {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 10px;

      .event {
        .event-container {
          display: flex;
          flex-direction: row;

          .event-info {
            flex: 1;
          }

          .event-organizer {
            opacity: 0.3;
            font-style: italic;
            margin-left: 5px;
          }

          .event-title {
            font-weight: bold;
          }

          .event-actions {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            padding-right: 10px;

            .event-actions-reset {
              display: flex;
              flex-direction: row;
              font-size: 12px;
              gap: 5px;
              margin-right: 10px;
              color: ${({ theme }) => theme.palette.text.secondary};
            }
          }
        }

        .event-bot {
          border-top: 1px solid rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
`;

const CalendarPage = () => {
  const dispatch = useDispatch();
  const dates = useSelector(calendarSelectors.getWeekDates);
  const previous = () => dispatch(calendarActions.previousWeek());
  const next = () => dispatch(calendarActions.nextWeek());
  const todayRef = useRef<HTMLDivElement>(null);
  const reset = () => {
    dispatch(calendarActions.resetWeek());
    scrollToToday();
  };
  const refresh = () => dispatch(api.util.invalidateTags(['CalendarEvent', 'CalendarEventsIntegration']));
  const { data: events, isFetching: eventsFetching } = useGetEventsQuery({
    start: dayjs(dates.startDate).format('YYYY-MM-DD'),
  });
  const eventsByDay = mapEventsToDays(events, dates.startDate);
  const { calendarReadPermission, googleCalendarReadPermission } = useProviderPermissionState();
  const isSettingsVisible = useSelector(appSelectors.getRecordingSettingsPanelOpened);

  const handleOpenSettings = () => {
    dispatch(appActions.setRecordingSettingsPanelOpened(true));
  };

  const scrollToToday = () => {
    if (todayRef.current) todayRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToToday();
    }, 100);
  }, [events]);

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        <Header>
          <ContainerFluid>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box flexGrow="1">Calendar</Box>
              {!isSettingsVisible && (
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    aria-label="Recording Settings"
                    startIcon={<SettingsIcon />}
                    onClick={handleOpenSettings}
                  >
                    Recording Settings
                  </Button>
                </Box>
              )}
            </Box>
          </ContainerFluid>
        </Header>
        {!calendarReadPermission && (
          <ContainerNarrow sx={{ paddingTop: '20px' }}>
            <AuthorizeCalendarPermissionCard />
          </ContainerNarrow>
        )}
        {googleCalendarReadPermission && (
          <SynchronizingContainer>
            <ContainerNarrow sx={{ paddingTop: '20px' }}>
              <Card>
                <Box display="flex" flexDirection="row" p={2}>
                  <Box width="100px" minHeight="75px">
                    <CircularProgress />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography variant={'h3'} gutterBottom>
                      Synchronizing your calendar
                    </Typography>
                    <Typography variant={'body1'} gutterBottom>
                      Please wait few seconds...
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </ContainerNarrow>
          </SynchronizingContainer>
        )}
        <ContainerNarrow sx={{ paddingTop: '20px' }}>
          <CalendarStyled>
            <div className="calendar-navigation">
              <Button variant="outlined" color="info" onClick={reset}>
                Today
              </Button>
              <div className="calendar-navigation-week">
                <IconButton aria-label="previous" onClick={previous}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <IconButton aria-label="next" onClick={next}>
                  <KeyboardArrowRightIcon />
                </IconButton>
              </div>
              <div className="calendar-navigation-info">
                {dayjs(dates.startDate).format('MMM DD')} - {dayjs(dates.endDate).format('MMM DD')} (W
                {dayjs(dates.startDate).week()})
              </div>
              <div className="calendar-navigation-actions">
                <LoadingButton
                  variant="text"
                  color="info"
                  size="large"
                  startIcon={<RefreshIcon />}
                  loadingPosition="start"
                  onClick={refresh}
                  loading={eventsFetching}
                >
                  Refresh
                </LoadingButton>
              </div>
            </div>
            <Divider sx={{ margin: '10px 0 20px 0' }} />
            {Object.entries(eventsByDay).map(([day, events]) => {
              const isToday = dayjs(day).isSame(dayjs(new Date()), 'day');
              return (
                <div key={`day-${day}`} ref={isToday ? todayRef : null} className="day">
                  <div className="date">
                    <div className={isToday ? 'date-container date-today' : 'date-container'}>
                      <div className="date-day">{dayjs(day).format('ddd')}</div>
                      <div className="date-number">{dayjs(day).format('DD')}</div>
                      <div className="date-month">{dayjs(day).format('MMM')}</div>
                    </div>
                  </div>
                  <div className="events">
                    {events.map((event) => (
                      <Event event={event} key={`event-${event.uuid}`} />
                    ))}
                  </div>
                </div>
              );
            })}
          </CalendarStyled>
        </ContainerNarrow>
      </Box>
      {isSettingsVisible && <RecordingSettingsPanel />}
    </Box>
  );
};

export default CalendarPage;

// what is the type of example?
type exampleType = {
  [key: string]: CalendarEvent[];
};

const mapEventsToDays = (events: CalendarEvent[] | undefined, startDate: Date): exampleType => {
  const startDay = dayjs(startDate);
  const initialDays = Object.fromEntries(
    Array.from({ length: 7 }, (_, i) => {
      const currentDate = startDay.add(i, 'day');
      return [currentDate.format('YYYY-MM-DD'), []];
    }),
  );
  if (!events) return initialDays;
  const filtererEvents = events.filter((event) => event.startDateTime);
  const sortedEvents = filtererEvents.sort((a, b) => dayjs(a.startDateTime).unix() - dayjs(b.startDateTime).unix());
  return sortedEvents.reduce((acc: exampleType, event: CalendarEvent) => {
    const day = dayjs(event.startDateTime).format('YYYY-MM-DD');
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(event);
    return acc;
  }, initialDays);
};

const Event = ({ event }: { event: CalendarEvent }) => {
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);

  const isPast = dayjs(event.endDateTime).isBefore(dayjs(new Date()));
  const [changeBot] = useBotMutation();

  const [botChecked, setBotChecked] = useState<boolean>(!!event.bot);
  useEffect(() => {
    setBotChecked(!!event.bot);
  }, [event.bot]);
  const handleBotChange = (switchEvent: any) => {
    setBotChecked(switchEvent.target.checked);
    changeBot({ meetingUuid: event.uuid, action: switchEvent.target.checked ? 'add' : 'remove' });
  };
  const handleBotReset = () => {
    changeBot({ meetingUuid: event.uuid, action: 'reset' });
  };

  const isLocked = useCallback(
    (video: Video): boolean => {
      if (!currentOrganization) return true;
      if (!currentOrganization.freePlan.free) return false;
      if (!currentOrganization.freePlan.limitDate) return false;

      return video.createdAt > currentOrganization.freePlan.limitDate;
    },
    [currentOrganization],
  );

  return (
    <Card className="event">
      <CardContent>
        <div className="event-container">
          <div className="event-info">
            <div>
              {dayjs(event.startDateTime).format('HH:mm')} - {dayjs(event.endDateTime).format('HH:mm')}
              <span className="event-organizer">(organizer: {event.organizerEmail})</span>
            </div>
            <div className="event-title">{event.summary}</div>

            {event.meetLink && (
              <Link href={event.meetLink} target="_blank" rel="noreferrer">
                {event.meetLink}
              </Link>
            )}

            {event.zoomLink && (
              <Link href={event.zoomLink} target="_blank" rel="noreferrer">
                {event.zoomLink}
              </Link>
            )}

            {event.teamsLink && (
              <Link href={event.teamsLink} target="_blank" rel="noreferrer">
                Join Teams Meeting
              </Link>
            )}
          </div>
          <div className="event-actions">
            {(event.meetLink || event.zoomLink || event.teamsLink) && (
              <FormControlLabel
                disabled={isPast}
                control={<Switch />}
                label="Record"
                checked={botChecked}
                onChange={handleBotChange}
              />
            )}
            {event.botRecordUserOverride !== null && !isPast && (
              <Tooltip
                sx={{ width: '50px' }}
                arrow
                placement="top"
                title={
                  <>
                    You've manually overridden the
                    <br />
                    auto-record settings for this event.
                    <br />
                    Click to reset.
                  </>
                }
              >
                <div className="event-actions-reset" onClick={handleBotReset}>
                  <div className="event-actions-reset-text">
                    <Button variant="text" size="small" endIcon={<InfoIcon />}>
                      Reset
                    </Button>
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      </CardContent>
      {(event.videos.length > 0 || event.bot) && (
        <CardActions className="event-bot">
          {event.videos.length === 0 && event.bot && (
            <Box display="flex" gap={1}>
              <Button
                size="small"
                color="info"
                startIcon={<AlarmOnIcon color="info" opacity="20%" />}
                variant="outlined"
                disabled
              >
                Recording Scheduled
              </Button>
              <BotLanguageSelect bot={event.bot} />
              <BotSummaryTemplateSelect bot={event.bot} />
            </Box>
          )}
          {event.videos.map((video) => (
            <div key={video.uuid}>
              {video.import === 'IMPORTED' && video.deletedAt !== null && (
                <Button
                  size="small"
                  color="info"
                  startIcon={<DoDisturbIcon color="info" opacity="20%" />}
                  variant="outlined"
                  disabled
                >
                  Deleted
                </Button>
              )}
              {video.import === 'IMPORTED' && video.deletedAt === null && (
                <>
                  {!isLocked(video) && (
                    <RouterLink to={`/o/${currentOrganizationUuid}/v/${video.uuid}`}>
                      <Button
                        size="small"
                        color="primary"
                        startIcon={<CheckCircleIcon color="primary" />}
                        variant="outlined"
                      >
                        Video
                      </Button>
                    </RouterLink>
                  )}
                  {isLocked(video) && (
                    <RouterLink to={`/o/${currentOrganizationUuid}/settings/plan`}>
                      <Button
                        size="small"
                        color="info"
                        startIcon={<CheckCircleIcon color="info" opacity="20%" />}
                        variant="outlined"
                        sx={{
                          animation: 'pulse 2s infinite',
                          '@keyframes pulse': {
                            '0%': { transform: 'scale(1)' },
                            '50%': { transform: 'scale(1.05)' },
                            '100%': { transform: 'scale(1)' },
                          },
                          '&:hover': {
                            transform: 'scale(1.05)',
                            transition: 'transform 0.2s',
                          },
                        }}
                      >
                        Unlock Video
                      </Button>
                    </RouterLink>
                  )}
                </>
              )}
              {video.import === 'TO_IMPORT' && (
                <RouterLink to={`/o/${currentOrganizationUuid}/v/${video.uuid}`}>
                  <Button size="small" color="info" startIcon={<TimelapseIcon color="info" />} variant="outlined">
                    Importing...
                  </Button>
                </RouterLink>
              )}
              {video.import === 'JOIN_REQUEST_DENIED' && (
                <Button
                  size="small"
                  color="info"
                  startIcon={<CancelIcon color="info" opacity="20%" />}
                  variant="outlined"
                  disabled
                >
                  Goelo denied by a participant
                </Button>
              )}
              {video.import === 'WAITING_ROOM_TIMEOUT' && (
                <Button
                  size="small"
                  color="info"
                  startIcon={<CancelIcon color="info" opacity="20%" />}
                  variant="outlined"
                  disabled
                >
                  No one admitted Goelo in the meeting
                </Button>
              )}
            </div>
          ))}
        </CardActions>
      )}
    </Card>
  );
};
