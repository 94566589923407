import { Box, Divider, FormControlLabel, IconButton, MenuItem, Select, Switch, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PeopleAlt } from '@mui/icons-material';
import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { Team, UserSetting } from 'redux/services/api.types';
import {
  useGetCurrentUserQuery,
  useGetCurrentUserSettingQuery,
  useUpdateCurrentUserSettingAutoRecordMutation,
} from 'redux/services/user';
import { Permission } from 'redux/services/video.types';
import MentionCard from 'components/mention/MentionCard';
import MentionCardOrganization from 'components/mention/MentionCardOrganization';
import { useDispatch, useSelector } from 'react-redux';
import appSelectors from 'redux/App/app.selectors';
import { useGetOrganizationQuery } from 'redux/services/organization';
import { useGetTeamsQuery } from 'redux/services/team';
import CloseIcon from '@mui/icons-material/Close';
import appActions from '../../../redux/App/app.actions';

interface FormValues {
  recordChecked: boolean;
  organizationSharing: Permission;
  teamSharing: { [key: string]: Permission };
  summaryRecipients: 'ACCEPTED_ATTENDEES' | 'BOT_INVITER_ONLY';
}

const RecordingSettingsPanel = () => {
  const dispatch = useDispatch();
  const handleCloseSettings = useCallback(() => {
    dispatch(appActions.setRecordingSettingsPanelOpened(false));
  }, [dispatch]);

  // Fetch data
  const currentOrganizationUuid = useSelector(appSelectors.getCurrentOrganizationUuid);
  const { data: currentOrganization } = useGetOrganizationQuery(currentOrganizationUuid);
  const { data: settings, isFetching: isFetchingSettings } = useGetCurrentUserSettingQuery();
  const { data: user } = useGetCurrentUserQuery();
  const { data: teams } = useGetTeamsQuery();

  const [updateRecordingSettingsMutation, updateRecordingsSettingsResult] =
    useUpdateCurrentUserSettingAutoRecordMutation();

  const getInitialValues = (settings: UserSetting | undefined, teams: Team[] | undefined): FormValues => {
    if (!settings) {
      return {
        recordChecked: false,
        organizationSharing: Permission.NONE,
        teamSharing: {},
        summaryRecipients: 'BOT_INVITER_ONLY',
      };
    }

    return {
      recordChecked: settings.autoRecordExternal && settings.autoRecordInternal && settings.autoRecordOrganizerOnly,
      organizationSharing: settings.defaultOrganizationPermission || Permission.NONE,
      teamSharing:
        teams?.reduce((acc, team) => {
          acc[team.uuid] = settings.defaultTeamPermissions?.[team.uuid] || Permission.NONE;
          return acc;
        }, {} as { [key: string]: Permission }) || {},
      summaryRecipients: settings.summaryRecipients || 'BOT_INVITER_ONLY',
    };
  };

  return (
    <Box
      sx={{
        width: '400px',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        borderLeft: '1px solid #e0e0e0',
      }}
    >
      {settings && (
        <Formik
          initialValues={getInitialValues(settings, teams)}
          onSubmit={(values) => {
            updateRecordingSettingsMutation({
              autoRecordExternal: values.recordChecked,
              autoRecordInternal: values.recordChecked,
              autoRecordOrganizerOnly: values.recordChecked,
              defaultOrganizationPermission: values.organizationSharing,
              defaultTeamPermissions: values.teamSharing,
              summaryRecipients: values.summaryRecipients,
            });
          }}
          key={JSON.stringify(settings)}
        >
          {({ values, handleChange, handleSubmit, dirty }) => (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  height: '56px',
                  px: 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderBottom: '1px solid #e0e0e0',
                }}
              >
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <IconButton size="small" onClick={handleCloseSettings}>
                    <CloseIcon />
                  </IconButton>
                  <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>
                    Recording Settings
                  </Typography>
                </Box>
                <LoadingButton
                  loading={updateRecordingsSettingsResult.isLoading}
                  variant="contained"
                  type="submit"
                  disabled={!dirty || isFetchingSettings}
                >
                  Save
                </LoadingButton>
              </Box>
              <Box sx={{ flex: 1, overflowY: 'auto' }}>
                <Box sx={{ p: 3 }}>
                  <Typography sx={{ fontSize: '13px', fontWeight: 600, color: '#111827', mb: 1 }}>
                    Choose which meetings to record.
                  </Typography>
                  {user && (
                    <Typography sx={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.6)', mb: 2 }}>
                      Calendar connected: {user.email}
                    </Typography>
                  )}
                  <FormControlLabel
                    sx={{
                      border: '1px solid #e0e0e0',
                      borderRadius: 1,
                      p: 1,
                      width: '100%',
                      m: 0,
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.01)',
                      },
                    }}
                    control={<Switch name="recordChecked" checked={values.recordChecked} onChange={handleChange} />}
                    label={<Typography sx={{ fontSize: '13px' }}>Record all meetings that I own</Typography>}
                  />
                </Box>
                <Divider />
                <Box sx={{ p: 3 }}>
                  <Typography sx={{ fontSize: '13px', fontWeight: 600, color: '#111827', mb: 1 }}>
                    Choose who receives the summary email.
                  </Typography>
                  <Typography sx={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.6)', mb: 2 }}>
                    An email with the meeting summary will be sent to the selected recipients after each meeting.
                  </Typography>
                  <Select
                    name="summaryRecipients"
                    value={values.summaryRecipients}
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                    sx={{
                      '.MuiSelect-select': { fontSize: '13px' },
                      backgroundColor: 'white',
                    }}
                  >
                    <MenuItem value="ACCEPTED_ATTENDEES" sx={{ fontSize: '13px' }}>
                      All accepted attendees
                    </MenuItem>
                    <MenuItem value="BOT_INVITER_ONLY" sx={{ fontSize: '13px' }}>
                      Only me
                    </MenuItem>
                  </Select>
                </Box>
                <Divider />
                <Box sx={{ px: 3, pt: 3 }}>
                  <Typography sx={{ fontSize: '13px', fontWeight: 600, color: '#111827', mb: 1 }}>
                    Choose the default sharing settings.
                  </Typography>
                  <Typography sx={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.6)', mb: 2 }}>
                    These settings will only apply to future recorded meetings. Previously recorded meetings will not be
                    affected.
                  </Typography>
                </Box>
                <Box sx={{ pr: 3, pl: 2 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <MentionCardOrganization secondary="Organization" organization={currentOrganization} />
                      <Select
                        name="organizationSharing"
                        value={values.organizationSharing}
                        onChange={handleChange}
                        size="small"
                        variant="outlined"
                        sx={{
                          minWidth: '150px',
                          '.MuiSelect-select': { fontSize: '13px' },
                          backgroundColor: 'white',
                        }}
                      >
                        <MenuItem value={Permission.COMMENT} sx={{ fontSize: '13px' }}>
                          Can comment
                        </MenuItem>
                        <MenuItem value={Permission.NONE} sx={{ fontSize: '13px' }}>
                          No access
                        </MenuItem>
                      </Select>
                    </Box>
                    {teams?.map((team) => (
                      <Box
                        key={team.uuid}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                      >
                        <MentionCard
                          avatar={<PeopleAlt />}
                          avatarBackgroundColor="transparent"
                          primary={team.name}
                          secondary="Team"
                        />
                        <Select
                          name={`teamSharing.${team.uuid}`}
                          value={values.teamSharing[team.uuid] || Permission.NONE}
                          onChange={handleChange}
                          size="small"
                          variant="outlined"
                          sx={{
                            minWidth: '150px',
                            '.MuiSelect-select': { fontSize: '13px' },
                            backgroundColor: 'white',
                          }}
                        >
                          <MenuItem value={Permission.COMMENT} sx={{ fontSize: '13px' }}>
                            Can comment
                          </MenuItem>
                          <MenuItem value={Permission.NONE} sx={{ fontSize: '13px' }}>
                            No access
                          </MenuItem>
                        </Select>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default RecordingSettingsPanel;
