import { createReducer } from '@reduxjs/toolkit';
import actions from './sidebar.actions';
import { State } from './sidebar.types';

const initialState: State = {
  selectedVideoComment: null,
  selectedVideoChat: null,
  selectedRecord: null,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.recordSelected, (state, action) => {
      state.selectedRecord = action.payload;
      state.selectedVideoComment = null;
      state.selectedVideoChat = null;
    })
    .addCase(actions.videoCommentSelected, (state, action) => {
      state.selectedVideoComment = action.payload;
      state.selectedVideoChat = null;
      state.selectedRecord = null;
    })
    .addCase(actions.videoChatSelected, (state, action) => {
      state.selectedVideoChat = action.payload;
      state.selectedVideoComment = null;
      state.selectedRecord = null;
    })
    .addCase(actions.close, (state) => {
      state.selectedVideoComment = null;
      state.selectedVideoChat = null;
      state.selectedRecord = null;
    });
});

export default reducer;
