import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from 'redux/rootTypes';

const actions = {
  currentOrganizationChanged: createAction('app/CURRENT_ORGANIZATION_CHANGED', withPayloadType<string>()),
  videoImportModalChanged: createAction('app/VIDEO_IMPORT_MODAL_CHANGED', withPayloadType<boolean>()),
  currentVideoChanged: createAction('app/CURRENT_VIDEO_CHANGED', withPayloadType<string | null>()),
  videoViewChanged: createAction('app/VIDEO_VIEW_CHANGED', withPayloadType<'gallery' | 'table'>()),
  setRecordingSettingsPanelOpened: createAction('app/SET_RECORDING_SETTINGS_PANEL_OPENED', withPayloadType<boolean>()),
};

export default actions;
