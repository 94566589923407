import { createSelector } from '@reduxjs/toolkit';
import { State } from './sidebar.types';

export const getSelectedVideoComment = (state: { sidebar: State }) => state.sidebar.selectedVideoComment;
export const getSelectedVideoChat = (state: { sidebar: State }) => state.sidebar.selectedVideoChat;
export const getSelectedRecord = (state: { sidebar: State }) => state.sidebar.selectedRecord;

export const isOpen = createSelector(
  [getSelectedVideoComment, getSelectedVideoChat, getSelectedRecord],
  (selectedVideoComment, selectedVideoChat, selectedRecord) =>
    selectedVideoComment !== null || selectedVideoChat !== null || selectedRecord !== null,
);

const selectors = {
  getSelectedVideoComment,
  getSelectedVideoChat,
  getSelectedRecord,
  isOpen,
};

export default selectors;
