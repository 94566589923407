import { State } from './app.types';

export const getCurrentOrganizationUuid = (state: { app: State }) => state.app.currentOrganizationUuid;
export const isVideoImportModalOpened = (state: { app: State }) => state.app.videoImportModalOpened;
export const getCurrentVideoUuid = (state: { app: State }) => state.app.currentVideoUuid;
export const getVideoView = (state: { app: State }) => state.app.videoView;
export const getRecordingSettingsPanelOpened = (state: { app: State }) => state.app.recordingSettingsPanelOpened;

const selectors = {
  getCurrentOrganizationUuid,
  isVideoImportModalOpened,
  getCurrentVideoUuid,
  getVideoView,
  getRecordingSettingsPanelOpened,
};

export default selectors;
